import React from 'react'
import { graphql } from 'gatsby'
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import { HTMLRenderer, Layout, PageWrapper, PrivacyPolicyWrapper } from "@retina-packages/retina-theme-bootstrap"
import MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer';
import { PageQueryResult, Props } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes';
import retinaConfig from '../../../utils/retinaConfigs';
import { CookiePolicy } from './styles'
import { cookiePage } from './constants';
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import { deriveSiteLogo, deriveSiteMenus, fullHTMLDataBuilder } from '../../../utils';
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders';
import { PrivacyPolicyContent } from '@retina-packages/retina-theme-bootstrap/packages/containers/ArticleContainer/style'

/** Rendering template for Primary block
 *
 * @param props props
 * @returns
 */
const CookieTemplate = (props: Props) => {

  const pageProps: PageQueryResult = props.data
  const blocks = pageProps.nodePage.relationships.field_paragraphs_gcso
  const pageContext = props.pageContext
  const audience = pageProps.nodePage.relationships.field_general_tags_gcso[0].name
  const mediaFiles = {
    nonSVGImages: pageContext.nonSVGImages,
    svgMediaImages: pageContext.svgMediaImages,
    allMediaDocument: pageContext.documents
  }
  const pagewrapper = "cookie-policy"
  const privacypolicypage = "cookie-policy"
  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp: pageProps, siteMainMenu: cookiePage.mainMenu, siteFooterMenu: cookiePage.footerMenu });
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({
    blocks,
    bannerTitle: '',
    carouselTitle: '',
    audience,
    footerTitle: cookiePage.footer,
    exitPopupTitle: cookiePage.exitPopup,
    hcpSwitcherTitle: cookiePage.hcpSwitcher,
    topNavTitle: cookiePage.topNav
  });


  const bannerHTML = fullHTMLDataBuilder({ blocks, title: cookiePage.banner })
  const cookieHTML = fullHTMLDataBuilder({ blocks, title: cookiePage.cookieContent })
  const siteLogos = deriveSiteLogo({ blocks, title: cookiePage.siteLogo })
  return (
    <CookiePolicy className='cookie-policy'>
      <PrivacyPolicyWrapper className={`footer ${privacypolicypage} privacy-policy`}>
        <div>
          {props?.pageContext && props?.pageContext.metaInfo && (
            <MetaTagContainer metaData={props?.pageContext?.metaInfo} />
          )}
          <Layout
            title={"siteTitle"}
            modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
            modalCloseButtonGTMLabel={retinaConfig.modalCloseButtonGTMLabel}
            location={props.location}
            data={mainMenu}
            mdata={footerMenu}
            footerData={footerText}
            audience={audience}
            exitPopData={exitData}
            hcplinks={hcpSwitcher}
            topNavigation={topNavs}
            siteLogos={siteLogos}
            staticMediaFiles={mediaFiles}
            backToTopContent={retinaConfig.backToTopContent}
            hcpValidate={retinaConfig.hcpValidate}
            preIndexUrl={retinaConfig.preIndexUrl}
            hcpHomeUrl={retinaConfig.hcpHomeUrl}
            hcpPrefix={retinaConfig.hcpPrefix}
            siteHomeUrl={retinaConfig.siteHomeUrl}
            footerClassName={cookiePage.footerClassName}
            cpraFooter={true}
            cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
            cpraOT={true}
            cpraOTEnv={process.env.OT_ENV}
            cpraOTuuid={process.env.OT_UUID}
            languageConfig={{ ...retinaConfig.langConfig, ...cookiePage.redirectLangUrl }}
            {... { ...retinaConfig.layoutAttrs.dtcAttrs, ...retinaConfig.layoutAttrs.commonAttrs }}
          >
            <PageWrapper className={`pagewrapper ${pagewrapper}`}>
              <MobileBanner className="mobile-banner clinical-trials-banner common-banner">
                <HTMLRenderer data={mediaFiles} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
              </MobileBanner>
              <PrivacyPolicyContent className="o-container">
                <HTMLRenderer data={mediaFiles} html={cookieHTML} tagName='div' className="privacypolicycontent" />
              </PrivacyPolicyContent>
            </PageWrapper>
          </Layout>
        </div>
      </PrivacyPolicyWrapper>
    </CookiePolicy>)
}

export default CookieTemplate

// Page query in GraphQL
export const pageQuery = graphql`
  query($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ...CookiePageQuery
      }
    }
  }
`