import styled from 'styled-components';
export const CookiePolicy = styled.div`
  .cookie-policy {
    .privacypolicycontent {
      padding-top: 80px;
      padding-bottom: 110px;

      .ot-sdk-show-settings {
        border: 1px solid #000 !important;

        &:before {
          top: 50% !important;
          transform: translateY(-50%) !important;
        }
      }

      p,
      li {
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 3.2rem;
      }
    }
  }
`;