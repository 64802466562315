export const cookiePage: any = {
  mainMenu: "Main Navigational Menu Consumer GCSO - Global",
  footerMenu: "Footer Navigation Menu Consumer GCSO - Global",
  footer: "Retina Global Footer Consumer - US - Global",
  exitPopup: "Retina Canada Exit Popup New - Global",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Global",
  topNav: "Top Navigational Menu Consumer US - Global",
  siteLogo: "Site logos - Global",
  cookieContent: "Cookie policy",
  banner: "Banner - HTML - Cookie Page - DTC - Global",
  footerClassName: "common-footer",
  redirectLangUrl: {
    "redirectUrl": {
      "en": "/cookie-policy",
      "es": "/hcp/cookie-policy"
    }
  },
}
